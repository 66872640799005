import './styles.scss'
import '@Components/Pages/Shipping/styles.scss'

import { Image } from '@Components/Pages/Shipping'
import MenuHeader from '@Components/shared/MenuPieces/MenuHeader'
import React from 'react'

const Shipping = () =>
  <div className='menu-page'>
    <div className='menu-page-shipping'>
      <MenuHeader title='Shipping' className='menu-page-shipping-header' />
      <Image className='menu-page-shipping-img' alt='shipping transport truck' />
      <div className='menu-page-shipping-text'>
        <p>Interested in one of our vehicles but are concerned about how far
        away it is?  We can help with that!  Our network of transporters across
        the country will be glad to help us get you your purchased vehicle
        delivered directly to your door. Just give us a call or email us and
        one of our friendly representatives will assist in getting you a quote
          to have your vehicle transported for you.</p>
        <p>Please feel free to contact CarVantedge at <a href='tel:8005392277'>(800) 539-2277</a> with
            any questions, comments, and/or concerns regarding this service.</p>
      </div>
    </div>
  </div>

export default Shipping
